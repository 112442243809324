import { React, useState, useEffect } from "react";
import { Box } from "@mui/material";
import { PaperSDKProvider, CheckoutWithCard } from "@paperxyz/react-client-sdk/dist";
import {useNavigate} from 'react-router-dom'
import "@paperxyz/react-client-sdk/dist/index.css";

const CardPay = () => {
    
    const navigate = useNavigate();

    const [JWTtoken, setJWTtoken] = useState();

    useEffect(() => {         
        setJWTtoken(localStorage.getItem("token"))
    }, []);
    return (
        <Box sx={{display: "flex", flexDirection: "column", maxWidth: "500px", margin: "auto", padding: "30px"}}>        
            <PaperSDKProvider chainName="Rinkeby">
                <CheckoutWithCard
                    // Generate a client secret with the Create Checkout SDK Intent API.
                sdkClientSecret={JWTtoken}
                    onPaymentSuccess={(result) => { console.log("success", result); alert("successfully sent"); navigate("/"); }}
                    onReview={(result) => { console.log("review clicked: ", result) }}
                    onError={(error) => { console.log("error:", error); alert("An error is occured in your payment"); navigate("/"); }}
                    options={{
                        colorBackground: '#121212',
                        colorPrimary: '#19A8D6',
                        colorText: '#f0f0f0',
                    borderRadius: 30,
                    }}
                ></CheckoutWithCard>                
            </PaperSDKProvider>
        </Box>
    )
}

export default CardPay