import { SET_WALLET_ADDRESS, SET_MINT_PRICE } from "../actionTypes";

const initState = {
    walletAddress: "",
    mintPrice: "",
}

function walletReducer(state = initState, action) {
    const {type, payload} = action;
    switch(type) {
        case SET_WALLET_ADDRESS: 
            return {
                ...state,
                walletAddress: payload
            }
        case SET_MINT_PRICE: 
            return {
                ...state,
                mintPrice: payload
            }
        default:
            return state
    }
}

export default walletReducer