import { ethers } from 'ethers';
import { NFTContractAddress } from '../config/config';



const getContract =async () => {
  if(window.ethereum){
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contractABI = require("../config/NFTContract.json");
    const contract = new ethers.Contract(NFTContractAddress, contractABI, signer);
    console.log("contract ", contract)
    return contract
  }    
}



export default getContract