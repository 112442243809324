import getContract from "./getNFTContract";
import { ethers } from 'ethers';

export const getNFTPrice = async () => {
  const contract = await getContract();
  try {
    const price = await contract.getNFTPrice();
    console.log("this is the price: ", price)
    return ethers.utils.formatEther(price);
  } catch (error) {
    console.log("catch")
    console.log(error);
    return "can't get the price"
  }
}

export const mint = async (to, quantity, price) => {
  const a = ethers.utils.parseEther(price.toString());
  console.log("aaaa", a)
  const contract = await getContract();
  try {
    await contract.mint(to, quantity, {value: ethers.utils.parseEther(price.toString())});
  } catch (error) {
    console.log("catch")
    console.log(error);
    return {
      status: error.message
    }
  }
}