import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from "react-redux";
import './App.css';
// import Minting from './pages/Minting/Minting';
import Layout from './pages/Layout/Layout';
import CryptoPay from './pages/CryptoPay/CryptoPay';
import PayOption from './pages/PayOption/PayOption';
import CardPaySetting from './pages/CardPaySetting/CardPaySetting';
import CardPay from './pages/CardPay/CardPay';
import store from './Redux/store';
function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout><PayOption/></Layout>} />
                    <Route path="/crypto" element={<Layout><CryptoPay/></Layout>} />
                    <Route path="/card" element={<Layout><CardPaySetting/></Layout>} />
                    <Route path="/card/pay" element={<Layout><CardPay/></Layout>} />
                </Routes>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
