import { React, useState } from "react";
import { Box, Typography } from "@mui/material";
import logo from "../../assets/images/logo.png";
import WalletConnectButton from "../../components/WalletConnectButton";
import { connectWallet } from "../../utils/walletInteraction";
import { updateWalletAddress } from "../../Redux/actions/walletAction";
import {useDispatch} from 'react-redux';
import "./TopNav.css"

const TopNav = () => {
    
    const dispatch = useDispatch()

    const [walletAddress, setWalletAddress] = useState("");

    const onClickConnectWallet = async () => {
        const walletResponse = await connectWallet();
        updateWalletAddress(walletResponse.address)(dispatch);
        setWalletAddress(walletResponse.address);
    }

    const onClickDisconnectWallet = () => {
        updateWalletAddress("")(dispatch);
        setWalletAddress("");
    }


    return (
        <Box className="header"
            sx={{
                padding:"20px",
                backgroundColor: "#1e7e36",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                position: "fixed",
                width: "100%",
                boxSizing: "border-box"
            }}
        >
            <Box className="logo">
                <img src={logo} className="logo"/>
            </Box>
            <Typography sx={{
                fontSize: {md: "40px", sm: "30px"},
                fontFamily: "VTC Carrie",
                color: "#ED5EB1"
            }}>
                Welcome To Minting
            </Typography>
            <WalletConnectButton
                onClickConnectWallet={onClickConnectWallet}
                onClickDisconnectWallet={onClickDisconnectWallet}
                walletAddress={walletAddress}
            />
        </Box>
    )
}

export default TopNav