import { React, useEffect } from "react";
import Banner from "../../assets/images/banner-freefree-nft.webp";
import { Box } from "@mui/material";
import { getNFTPrice } from "../../utils/NFTContractFunctions";
import { updateMintPrice } from "../../Redux/actions/walletAction";
import { useDispatch, useSelector } from 'react-redux';
import TopNav from "../TopNav/TopNav";
const Layout = ({ children }) => { 
    
    const dispatch = useDispatch();

    
    const walletAddress = useSelector(state => state.walletReducer.walletAddress);

    useEffect(() => {         
        const init =async() => {
            const price = await getNFTPrice();
            updateMintPrice(price)(dispatch);
        }
        init()        
    }, []);

    useEffect(() => {
        const init =async() => {
            const price = await getNFTPrice();
            updateMintPrice(price)(dispatch);
        }
        init()  
    }, [walletAddress])

    return (
        <Box>            
            <TopNav/>
            <Box className="hero" sx={{paddingTop: "94px"}}>
                <img src={Banner} width="100%"/>
            </Box>
            {children}

        </Box>
        
    )
    
}

export default Layout