import { React, useState } from "react";
import { Box, Typography, TextField, Button, Grid } from "@mui/material";
import { API_KEY, MINT_PRICE, Contract_Id } from "../../config/config";
import {useNavigate} from 'react-router-dom'

const CardPaySetting = () => {
    
    const navigate = useNavigate();

    const [userWalletAddress, setUserWalletAddress] = useState("");
    const [email, setEmail] = useState("");
    const [mintAmount, setMintAmount] = useState();    
    const [JWTtoken, setJWTtoken] = useState("")
    
    const nextHandler = async (e) => {
        e.preventDefault();
        const options = {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
              Authorization: `Bearer ${API_KEY}`
            },
            body: JSON.stringify({
              quantity: parseInt(mintAmount),
              metadata: {},
              expiresInMinutes: 120,
              usePaperKey: false,
              hideApplePayGooglePay: false,
              mintMethod: {  
                "name": "mint",  
                "args": {    
                    "to": "$WALLET",    
                    "quantity": "$QUANTITY"
                },  
                "payment": {    // Change this to "0 * $QUANTITY" if you\'re doing a free claim    
                    "value": `${MINT_PRICE} * $QUANTITY`,    // Leave the currency as the native coin for that chain if your collection is free    
                    "currency": "ETH"  }},
              contractId: Contract_Id,
              walletAddress: userWalletAddress,
              email: email
            })
          };
          
          fetch('https://paper.xyz/api/2022-08-12/checkout-sdk-intent', options)
            .then(response => response.json())
            .then(response => {
                console.log(response); 
                setJWTtoken(response.sdkClientSecret);
                localStorage.setItem("token", response.sdkClientSecret);
                navigate("/card/pay");
            })
            .catch(err => alert(err));
    }
    return (
        <Box
            sx={{display: "flex", flexDirection: "column", maxWidth: {sm: "500px"}, margin: "auto", marginTop: "20px", marginBottom: "20px", paddingX: {sm:"0", xs: "20px"}}}
        >
            <form onSubmit={nextHandler}>
            <Grid container spacing={2} sx={{display: {sm: "flex", xs: "flex"}, textAlign: "center", marginBottom: "20px", alignItems: "center" }}>
                <Grid item xs={12} sm={6}>
                    <Typography sx={{fontSize: {sm: "20px"}}}>Wallet Address for NFT :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="userWalletAddress"
                        name="userWalletAddress"
                        margin="normal"
                        required
                        value={userWalletAddress}
                        onChange={(e) => setUserWalletAddress(e.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{display: {sm: "flex", xs: "flex"}, textAlign: "center", marginBottom: "20px", alignItems: "center" }}>
                <Grid item xs={12} sm={6}>
                    <Typography sx={{fontSize: {sm: "20px"}}}>Email :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="email"
                        name="email"
                        margin="normal"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{display: {sm: "flex", xs: "flex"}, textAlign: "center", marginBottom: "20px", alignItems: "center" }}>
                <Grid item xs={12} sm={6}>
                    <Typography sx={{fontSize: {sm: "20px"}}}>Mint Amount :</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="mintAmount"
                        name="mintAmount"
                        margin="normal"
                        required
                        value={mintAmount}
                        onChange={(e) => setMintAmount(e.target.value)}
                    />
                </Grid>
            </Grid>
            
            <Box sx={{textAlign: "center"}}>
                <Button 
                    type="submit"
                    variant="contained"
                    color="success"
                    sx={{fontSize: {md: 14, sm: 12, xs: 10}, mt: 3}}
                    fullWidth
                    >
                    Next
                </Button>
            </Box>
            </form>
        </Box>
    )
}

export default CardPaySetting