// ** Redux Imports
import rootReducer from './reducers'
import { configureStore } from '@reduxjs/toolkit'
const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: false
    })
  }
})

export default store