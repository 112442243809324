import { SET_MINT_PRICE, SET_WALLET_ADDRESS } from "../actionTypes";

export const updateWalletAddress = (address) => (dispatch) => {
    dispatch({
        type: SET_WALLET_ADDRESS,
        payload: address
    })
}

export const updateMintPrice = (price) => (dispatch) => {
    dispatch({
        type: SET_MINT_PRICE,
        payload: price
    })
}
