import { React, useState, useEffect} from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { useSelector } from 'react-redux';
import { mint } from "../../utils/NFTContractFunctions";
const CryptoPay = () => {
    
    const [mintAmount, setMintAmount] = useState(0);

    const walletAddress = useSelector(state => state.walletReducer.walletAddress);
    const mintPrice = useSelector(state => state.walletReducer.mintPrice);

    const mintButtonHandler = async () => {
        console.log("type", walletAddress, mintPrice, mintAmount);
        if(!walletAddress) {
            alert("please connect wallet")
            return;
        }
        await mint(walletAddress, mintAmount, mintPrice * mintAmount);
    }

    return (
        <Box
            sx={{display: "flex", flexDirection: "column", maxWidth: {sm: "500px"}, margin: "auto", marginTop: "20px", marginBottom: "20px", paddingX: {sm:"0", xs: "20px"}}}
        >
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <Typography sx={{fontSize: {sm: "24px"}}}>Mint Price (ETH) :</Typography>
                <TextField
                    id="mintPrice"
                    name="mintPrice"
                    margin="normal"
                    disabled
                    value={mintPrice}
                />
            </Box>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <Typography sx={{fontSize: {sm: "24px"}}}>Mint Amount :</Typography>
                <TextField
                    required
                    type="number"
                    id="mintAmount"
                    name="mintAmount"
                    margin="normal"
                    value={mintAmount}
                    onChange={(e) => setMintAmount(e.target.value > 0 ? e.target.value : 0)}
                />
            </Box>
            <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                <Typography sx={{fontSize: {sm: "24px"}}}>Total Price (ETH) :</Typography>
                <TextField
                    id="mintAmount"
                    name="mintAmount"
                    margin="normal"
                    value={mintAmount * mintPrice}
                />
            </Box>
            <Box sx={{textAlign: "center"}}>
                <Button 
                    variant="contained"
                    color="success"
                    sx={{fontSize: {md: 14, sm: 12, xs: 10}, mt: 3}}
                    fullWidth
                    onClick={mintButtonHandler}
                    >
                    Mint
                </Button>
            </Box>
            
        </Box>
    )
}

export default CryptoPay