import React from "react";
import { Box, Button } from "@mui/material";
import {useNavigate} from 'react-router-dom'
import { useSelector } from 'react-redux';
const PayOption = () => {

    const navigate = useNavigate();
    
    const walletAddress = useSelector(state => state.walletReducer.walletAddress);

    const clickCryptoPay = () => {
        if(!walletAddress) {
            alert("please connect wallet");
            return
        }
        navigate("/crypto")
    }

    return (
        <Box
            sx={{display: "flex", flexDirection: "column", maxWidth: "500px", margin: "auto", padding: "30px"}}
        >
            <Button 
                variant="contained"
                color="success"
                sx={{fontSize: {md: 14, sm: 12, xs: 10}, mt: 3}}
                fullWidth
                onClick={clickCryptoPay}
                >
                Buy With Crypto
            </Button>
            <Button 
                variant="contained"
                color="success"
                sx={{fontSize: {md: 14, sm: 12, xs: 10}, mt: 3}}
                fullWidth
                onClick={() => navigate("/card")}
                >
                Buy With Credit Card
            </Button>
        </Box>
    )
}

export default PayOption